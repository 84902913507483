import React from "react";

//Components
import { Container } from "@hybris-software/ui-kit/";

//Assets
import teamMember1 from "../../../../Assets/images/teamMember1.png";
import teamMember2 from "../../../../Assets/images/teamMember2.png";
import teamMember3 from "../../../../Assets/images/teamMember3.png";

// Icons
import { FaLinkedinIn } from "react-icons/fa";

//Styles
import Style from "./Team.module.css";

const teamMembers = [
  {
    image: teamMember1,
    fullName: "Mehdi Cherif",
    role: "CEO",
    cardColor: "#89EB5B",
    link: "https://www.linkedin.com/in/mehdicherif/",
  },
];

const Team = () => {
  return (
    <Container className={Style.mainContainer}>
      <h3>Meet the pulse team</h3>
      <div className={Style.teamGrid}>
        {teamMembers.map((member, index) => {
          return (
            <div className={Style.cardContainer} key={index}>
              <div
                className={Style.color}
                style={{ background: member.cardColor }}
              ></div>
              <div className={Style.card}>
                <div
                  className={Style.image}
                  style={{ backgroundImage: `url(${member.image})` }}
                ></div>
                <h4>{member.fullName}</h4>
                <p>
                  {member.role}
                </p>
                <a href={member.link} rel="noopener noreferrer" target="_blank">
                  <div className={Style.linkedin}>
                    <FaLinkedinIn />
                  </div>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default Team;
